import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import {
  Formik,
} from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Modal,
} from '@kajabi/sage-react';
import AppContext from '../../../../../AppContext';
import Select from '../../../../shared/formik/Select';
import Textarea from '../../../../shared/formik/Textarea';
import UsersShowContext from '../../../../../contexts/UsersShowContext';
import projectPost from '../../../../../scripts/projectPost';
import {
  categories as categoriesData,
} from '../../../../../data/categories/categories';
import {
  validationStringRequired,
} from '../../../../../data/shared/validations';
import withActiveCategoriesOnly from '../../../../../scripts/withActiveCategoriesOnly';

const selectCategoryPrompt = 'Select a category';
const subcategories = withActiveCategoriesOnly(categoriesData).reduce(
  (acc, category) => {
    const activeSubcategories = withActiveCategoriesOnly(category.subcategories);
    for (let i = 0; i < activeSubcategories.length; i += 1) {
      const subcategory = activeSubcategories[i];
      acc[subcategory.slug] = {
        categoryId: category.id,
        categoryTitle: category.title,
        categorySlug: category.slug,
        subcategoryId: subcategory.id,
        subcategoryTitle: subcategory.title,
        subcategorySlug: subcategory.slug,
      };
    }
    return acc;
  },
  {},
);

export default function NewProjectMessageModal({
  active,
  onClose,
}) {
  const { setToastData } = useContext(AppContext);
  const {
    userData,
  } = useContext(UsersShowContext);

  const selectCategoryOptions = userData.filtered_services
    .filter((service) => window.globalData.categories[service.categorySlug]?.active)
    .map((service) => ({
      value: service.subcategorySlug,
      label: service.subcategoryName,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const initialValues = {
    description: '',
    category: selectCategoryOptions.some((category) => category.value === 'content-migration') ? 'content-migration' : null,
    vendorId: userData.id,
  };

  return (
    <Modal
      active={active}
      className="new-project-message-modal"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          setSubmitting(false);

          const {
            category,
            ...restValues
          } = values;
          const categoryObj = subcategories[category];

          if (categoryObj) {
            projectPost({
              ...categoryObj,
              ...restValues,
            }, () => {
              setToastData({
                key: uuid(),
                icon: 'check-circle-filled',
                title: 'Message sent!',
              });
              resetForm();
              onClose();
              window.location.reload();
            });
          } else {
            setToastData({
              key: uuid(),
              icon: 'warning-filled',
              type: 'danger',
              title: 'This category cannot be selected. Please contact support.',
            });
          }
        }}
        validationSchema={Yup.object({
          description: Yup.string().required(validationStringRequired),
          category: Yup.string().nullable().required(validationStringRequired)
            .notOneOf([selectCategoryPrompt], validationStringRequired),
        })}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          resetForm,
          touched,
          values,
        }) => (
          <>
            <Modal.Header
              aside={(
                <Button
                  color="secondary"
                  icon="remove"
                  iconOnly
                  onClick={onClose}
                  subtle
                />
              )}
              subheader="Let this expert know what you need help with."
              title="Message about a new project"
            />
            <Modal.Body>
              <Select
                handleChange={handleChange}
                idName="category"
                label="What type of project are you looking to start?"
                options={[{ value: null, label: selectCategoryPrompt }, ...selectCategoryOptions]}
                touched={touched.category}
                value={values.category}
                hasError={!!errors.category}
              />
              <Textarea
                handleChange={handleChange}
                idName="description"
                label="Message"
                rows={8}
                placeholder={
`Hi!

I need help migrating my business to Kajabi — importing customers and courses, setting up a new website and sales pages, and emails with automations to power it all. Can you help?`
                }
                touched={touched.description}
                value={values.description}
                errors={errors.description}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button.Group gap="sm">
                <Button
                  color="secondary"
                  onClick={() => {
                    resetForm();
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting || (errors.length === 0 || values.description === '')}
                  onClick={handleSubmit}
                >
                  Send
                </Button>
              </Button.Group>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
}

NewProjectMessageModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
